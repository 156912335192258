const EMPLOYEE_AUTH_BASE = 'EMPLOYEE_AUTH::';

export type EmployeeAuthError = {
  message?: string;
  statusCode?: number;
};
/**
 * State object types
 */
export type EmployeeAuthState = {
  loading: boolean;
  loggedIn: boolean;
  employeeId?: number;
  token?: string;
  error?: EmployeeAuthError;
};

/**
 * Action types
 */
export const LOG_IN_PENDING = `${EMPLOYEE_AUTH_BASE}LOG IN PENDING`;
export const LOG_IN_SUCCESS = `${EMPLOYEE_AUTH_BASE}LOG IN SUCCEEDED`;
export const LOGIN_FAILED = `${EMPLOYEE_AUTH_BASE}LOG IN FAILED`;
export const LOG_OUT = `${EMPLOYEE_AUTH_BASE}LOG OUT`;
export const CHECK_LOGIN_STATUS = `${EMPLOYEE_AUTH_BASE}CHECK LOGIN STATUS`;

export interface EmployeeLoginAction {
  type: typeof LOG_IN_PENDING;
  payload: {
    employeeId: number;
    pincode: string;
  };
}
export interface EmployeeLoginSuccessAction {
  type: typeof LOG_IN_SUCCESS;
  payload: {
    employeeId: number;
    token: string;
  };
}

export interface EmployeeLoginFailedAction {
  type: typeof LOGIN_FAILED;
  payload?: EmployeeAuthError;
}

export interface LogoutAction {
  type: typeof LOG_OUT;
}

export interface CheckLoginStatusAction {
  type: typeof CHECK_LOGIN_STATUS;
}

export type AuthStateActionTypes =
  | EmployeeLoginAction
  | EmployeeLoginSuccessAction
  | EmployeeLoginFailedAction
  | LogoutAction
  | CheckLoginStatusAction;
