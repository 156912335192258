const APP_STATE_PREFIX = 'APP_STATE::';

export type HomeStateState =
  | 'home'
  | 'employeeLogin'
  | 'answerQuestions'
  | 'finishQuestions'
  | 'noQuestions'
  | 'employeeSignup'
  | 'employeeSignupSuccess';

export const HOME_STATE_ROUTE: HomeStateState = 'home';
export const EMPLOYEE_LOGIN_STATE_ROUTE: HomeStateState = 'employeeLogin';
export const EMPLOYEE_SIGNUP_STATE_ROUTE: HomeStateState = 'employeeSignup';
export const EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE: HomeStateState =
  'employeeSignupSuccess';
export const ANSWER_QUESTIONS_STATE_ROUTE: HomeStateState = 'answerQuestions';
export const FINISH_QUESTIONS_STATE_ROUTE: HomeStateState = 'finishQuestions';
export const NO_QUESTIONS_STATE_ROUTE: HomeStateState = 'noQuestions';

const HOME_STATE_PREFIX = `${APP_STATE_PREFIX}HOME_STATE::`;

export const HOME = `${HOME_STATE_PREFIX}HOME`;
export const EMPLOYEE_LOGIN = `${HOME_STATE_PREFIX}EMPLOYEE_LOGIN`;
export const EMPLOYEE_SIGNUP = `${HOME_STATE_PREFIX}EMPLOYEE_SIGNUP`;
export const EMPLOYEE_SIGNUP_SUCCESS = `${HOME_STATE_PREFIX}Employee Signup Success`;
export const ANSWER_QUESTIONS = `${HOME_STATE_PREFIX}ANSWER_QUESTIONS`;
export const FINISH_QUESTIONS = `${HOME_STATE_PREFIX}FINISH_QUESTIONS`;
export const NO_QUESTIONS = `${HOME_STATE_PREFIX}NO_QUESTIONS`;

export interface Transition {
  from: HomeStateState;
  to: HomeStateState;
}

export interface AppStateState {
  homeState: HomeStateState;
}

export interface EnterHomeAction {
  type: typeof HOME;
}

export interface EnterEmployeeLoginAction {
  type: typeof EMPLOYEE_LOGIN;
}

export interface EnterEmployeeSignupAction {
  type: typeof EMPLOYEE_SIGNUP;
}
export interface EnterEmployeeSignupSuccessAction {
  type: typeof EMPLOYEE_SIGNUP_SUCCESS;
}

export interface EnterAnswerQuestionsAction {
  type: typeof ANSWER_QUESTIONS;
}

export interface EnterFinishQuestionsAction {
  type: typeof FINISH_QUESTIONS;
}

export interface EnterNoQuestionsAction {
  type: typeof NO_QUESTIONS;
}

export type Actions =
  | EnterHomeAction
  | EnterEmployeeLoginAction
  | EnterEmployeeSignupAction
  | EnterAnswerQuestionsAction
  | EnterFinishQuestionsAction
  | EnterNoQuestionsAction;

export class HomeStateTransisionError extends Error {}
