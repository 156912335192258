const API_BASE = process.env.REACT_APP_API_BASE_URL;
const WEB_PREFIX = '/web';
export default {
  BASE: API_BASE,
  SESSION: `${WEB_PREFIX}/session`,
  EMPLOYEES: `${WEB_PREFIX}/employees`,
  QUESTION: `${WEB_PREFIX}/questions`,
  EMPLOYEE_AUTHENTICATE: `${WEB_PREFIX}/employees/authenticate`,
  ANSWERS: `${WEB_PREFIX}/answer`,
  SIGNUP: `${WEB_PREFIX}/signup`,
  START_QUESTIONAIRE: `${WEB_PREFIX}/answer/start`,
};
