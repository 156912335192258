import React, { useCallback } from 'react';
import Header from 'components/atoms/Header';
import Employee from 'types/Employee';
import UserAvatar from 'components/atoms/UserAvatar';
import { ChevronRight } from 'heroicons-react';
import { useTranslation } from 'react-i18next';

interface EmployeeListItemProp {
  employee: Employee;
  active: boolean;
  disabled?: boolean;
  onClick: (employee: Employee | null) => void;
}

const EmployeeListItem: React.FC<EmployeeListItemProp> = ({
  employee,
  active,
  disabled = false,
  onClick,
}) => {
  const [t] = useTranslation();
  const handleClick = useCallback(() => onClick(!active ? employee : null), [
    active,
    employee,
    onClick,
  ]);

  return (
    <button
      type="button"
      disabled={disabled}
      className={`group h-20 py-4 px-6 w-full flex items-center justify-between hover:bg-primary hover:text-white focus:outline-none focus:border-color-primary ${
        active ? 'bg-primary text-white' : ''
      } ${disabled ? 'disabled:opacity-50' : ''}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
    >
      <div className="flex items-center">
        <UserAvatar imageSrc={employee.imageSrc} />
        <div className="ml-4 break-all">
          <Header size="sm">
            {employee.name ?? `${t('common:employee')} ${employee.id}`}
          </Header>
        </div>
      </div>
      <div className="flex flex-row items-center mx-2">
        {employee.questionsPending && (
          <div
            className={`rounded-full w-4 h-4 bg-primary group-hover:bg-white ${
              active ? 'bg-white' : ''
            }`}
          />
        )}
        <ChevronRight size={30} />
      </div>
    </button>
  );
};

export default EmployeeListItem;
