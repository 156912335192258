import Department from 'types/Department';
import {
  AuthError,
  LoginAction,
  LoginSuccessAction,
  LoginFailedAction,
  LogoutAction,
  CheckLoginStatusAction,
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  LOG_OUT,
  CHECK_LOGIN_STATUS,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_SUCCESS,
  FetchDepartmentPending,
  GET_DEPARTMENT_PENDING,
  FetchDepartmentSuccess,
  FetchDepartmentFailed,
  USER_SIGNUP_PENDING,
  UserSignupStart,
  UserSignupFailed,
  USER_SIGNUP_FAILED,
  UserSignupSuccess,
  USER_SIGNUP_SUCCESS,
} from './types';

export const logInStart = (
  username: string,
  password: string
): LoginAction => ({
  type: LOG_IN_PENDING,
  payload: {
    username,
    password,
  },
});

export const logInSuccess = (): LoginSuccessAction => ({
  type: LOG_IN_SUCCESS,
});

export const logInFailure = (error: AuthError): LoginFailedAction => ({
  type: LOGIN_FAILED,
  payload: error,
});

export const logOut = (): LogoutAction => ({
  type: LOG_OUT,
});

export const checkLoginStatus = (): CheckLoginStatusAction => ({
  type: CHECK_LOGIN_STATUS,
});

export const fetchDepartment = (): FetchDepartmentPending => ({
  type: GET_DEPARTMENT_PENDING,
});

export const fetchDepartmentSuccess = (
  identity: Department
): FetchDepartmentSuccess => ({
  type: GET_DEPARTMENT_SUCCESS,
  payload: identity,
});

export const fetchDepartmentFailed = (
  message: string,
  statusCode: number
): FetchDepartmentFailed => ({
  type: GET_DEPARTMENT_FAILED,
  payload: {
    message,
    statusCode,
  },
});

export const userSignupStart = (
  username: string,
  password: string,
  name: string
): UserSignupStart => ({
  type: USER_SIGNUP_PENDING,
  payload: { username, password, name },
});

export const userSignupSuccess = (): UserSignupSuccess => ({
  type: USER_SIGNUP_SUCCESS,
});
export const userSignupFailed = (error: AuthError): UserSignupFailed => ({
  type: USER_SIGNUP_FAILED,
  payload: error,
});
