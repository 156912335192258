import { put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { isEmpty } from 'lodash';
import { Api, URL_CONSTANTS } from 'services/api';
import { enterFinishQuestions, enterNoQuestions } from 'store/appState';
import { fetchQuestionsFailed, fetchQuestionsSuccess } from './actions';
import {
  FETCH_QUESTIONS_PENDING,
  NormalizedQuestions,
  START_QUESTIONAIRE,
  QUESTIONS_FINISH,
  FetchAction,
  FinishQuestionActions,
  StartQuestionaireAction,
} from './types';
import QuestionSchema from './schema';

function* handleOnFetch(action: FetchAction) {
  try {
    const apiRes = yield Api.get(
      `${URL_CONSTANTS.QUESTION}?employeeToken=${action.payload.token}`
    ).then((res) => res.data.questions);
    const { entities, result }: NormalizedQuestions = yield normalize(apiRes, [
      QuestionSchema,
    ]);
    yield put(fetchQuestionsSuccess(entities.questions, result));
    if (isEmpty(entities.questions)) yield put(enterNoQuestions());
  } catch (e) {
    yield put(fetchQuestionsFailed(e.message));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchQuestions() {
  yield takeLatest(FETCH_QUESTIONS_PENDING, handleOnFetch);
}

function* handleFinish(action: FinishQuestionActions) {
  try {
    yield Api.post(URL_CONSTANTS.ANSWERS, {
      employeeToken: action.payload.token,
      answers: action.payload.answers,
    });
    yield put(enterFinishQuestions());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFinishQuestion() {
  yield takeLatest(QUESTIONS_FINISH, handleFinish);
}

function* handleStartQuestionaire(action: StartQuestionaireAction) {
  try {
    yield Api.post(URL_CONSTANTS.START_QUESTIONAIRE, {
      employeeToken: action.payload.token,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onQuestionaireStart() {
  yield takeLatest(START_QUESTIONAIRE, handleStartQuestionaire);
}

export default [onFetchQuestions, onFinishQuestion, onQuestionaireStart];
