import {
  AuthState,
  AuthStateActionTypes,
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
  LoginFailedAction,
  FetchDepartmentSuccess,
  FetchDepartmentFailed,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_PENDING,
  GET_DEPARTMENT_SUCCESS,
  USER_SIGNUP_PENDING,
  USER_SIGNUP_FAILED,
  USER_SIGNUP_SUCCESS,
} from './types';

export const initialState: AuthState = {
  loading: false,
  loggedIn: false,
  error: undefined,
  department: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case CHECK_LOGIN_STATUS:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_PENDING:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        error: undefined,
      };
    case LOGIN_FAILED: {
      const fetchAction = action as LoginFailedAction;
      return {
        ...state,
        error: fetchAction.payload,
        loading: false,
      };
    }
    case GET_DEPARTMENT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPARTMENT_FAILED: {
      const newAction = action as FetchDepartmentFailed;
      return {
        ...state,
        loading: false,
        error: newAction.payload,
      };
    }
    case GET_DEPARTMENT_SUCCESS: {
      const newAction = action as FetchDepartmentSuccess;
      return {
        ...state,
        loading: false,
        department: newAction.payload,
      };
    }
    case USER_SIGNUP_PENDING:
      return {
        ...state,
        loading: true,
      };
    case USER_SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOG_OUT:
      return initialState;
  }
  return state;
};

export default authReducer;
