import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Base from 'components/templates/Base';
import EmployeeList from 'components/organisms/EmployeeList';
import Grid from 'components/templates/Grid';
import Employee from 'types/Employee';
import { RootState } from 'store/configuration';
import { fetchEmployees } from 'store/employees';
import HomeStateRouter from 'components/templates/HomeStateRouter';
import { enterEmployeeLogin, enterHome } from 'store/appState';
import { fetchDepartment } from 'store/auth';

const Home = (): ReactElement => {
  const [currentEmployee, setCurrentEmployee] = useState<Employee | null>(null);
  const dispatch = useDispatch();
  const { department } = useSelector((state: RootState) => state.auth);
  const { entities, result, error, loading } = useSelector(
    (state: RootState) => state.employees
  );

  const handleSelectEmployee = useCallback(
    (employee: Employee | null) => {
      setCurrentEmployee(employee);

      if (employee) return dispatch(enterEmployeeLogin());
      return dispatch(enterHome());
    },
    [setCurrentEmployee, dispatch]
  );

  useEffect(() => {
    if (isEmpty(entities) && !loading && !error) {
      dispatch(fetchEmployees());
    }

    if (isEmpty(department) && !loading) {
      dispatch(fetchDepartment());
    }
  }, [loading, entities, error, dispatch, department]);

  return (
    <Base>
      <Grid
        sidebar={
          <EmployeeList
            department={department}
            employees={{ entities, result }}
            loading={loading}
            selectedEmployee={currentEmployee}
            onSelect={(employee: Employee | null) =>
              handleSelectEmployee(employee)
            }
          />
        }
      >
        <HomeStateRouter employee={currentEmployee} />
      </Grid>
    </Base>
  );
};

export default Home;
