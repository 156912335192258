import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import authReducer, {
  sagas as authSagas,
  initialState as authInitialState,
} from './auth';
import employeeAuthReducer, {
  sagas as employeeAauthSagas,
  initialState as employeeAuthInitialState,
} from './employeeAuth';
import employeesReducer, {
  sagas as employeesSagas,
  initialState as employeesInitialState,
} from './employees';
import questionsReducer, {
  sagas as questionsSaga,
  initialState as questionsInitialState,
} from './questions';
import appStateReducer, {
  initialState as appStateInitialState,
} from './appState';
import { CLEAR_DATA, State } from './types';

const sagaMiddleware = createSagaMiddleware();

const initialState: State = {
  auth: authInitialState,
  employeeAuth: employeeAuthInitialState,
  employees: employeesInitialState,
  questions: questionsInitialState,
  appState: appStateInitialState,
};

const appReducer = combineReducers<State>({
  auth: authReducer,
  employeeAuth: employeeAuthReducer,
  employees: employeesReducer,
  appState: appStateReducer,
  questions: questionsReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === CLEAR_DATA) {
    return initialState;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;

function* rootSaga() {
  try {
    yield all(
      [
        ...authSagas,
        ...employeesSagas,
        ...questionsSaga,
        ...employeeAauthSagas,
      ].map(fork)
    );
  } catch (e) {
    throw new Error(e);
  }
}

sagaMiddleware.run(rootSaga);
