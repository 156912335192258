import { NormalizedSchema } from 'normalizr';
import Answer from 'types/Answer';
import ReduxReducer from 'types/ReduxReducer';
import Question from 'types/Question';

const PREFIX = 'QUESTIONS:: ';

export interface QuestionsSchemaEntity {
  questions: Array<Question>;
}

export interface QuestionsState extends ReduxReducer {
  entities: Question[];
  result: number[];
}

export const FETCH_QUESTIONS_PENDING = `${PREFIX}Fetching`;
export const FETCH_QUESTIONS_SUCCESS = `${PREFIX}Fetch succeeded`;
export const FETCH_QUESTIONS_FAILED = `${PREFIX}Fetch failed`;

export const START_QUESTIONAIRE = `${PREFIX}Start`;
export const QUESTIONS_FINISH = `${PREFIX}Finish`;

export interface FetchAction {
  type: typeof FETCH_QUESTIONS_PENDING;
  payload: { token?: string };
}

export interface FetchSuccessAction {
  type: typeof FETCH_QUESTIONS_SUCCESS;
  payload: {
    entities: Question[];
    result: number[];
  };
}

export interface FetchFailedAction {
  type: typeof FETCH_QUESTIONS_FAILED;
  payload: {
    message: string;
  };
}

export interface StartQuestionaireAction {
  type: typeof START_QUESTIONAIRE;
  payload: {
    token: string;
  };
}

export interface FinishQuestionActions {
  type: typeof QUESTIONS_FINISH;
  payload: {
    answers: Answer[];
    token?: string;
  };
}

export type NormalizedQuestions = NormalizedSchema<
  QuestionsSchemaEntity,
  number[]
>;

export type Actions =
  | FetchAction
  | FetchFailedAction
  | FetchSuccessAction
  | FinishQuestionActions
  | StartQuestionaireAction;
