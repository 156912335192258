import React, { ReactNode } from 'react';

interface GridProp {
  sidebar: ReactNode;
}

const Grid: React.FC<GridProp> = ({ sidebar, children }) => {
  return (
    <div className="grid grid-cols-5 gap-4 h-full w-full">
      <div className="col-span-2">{sidebar}</div>
      <div className="col-span-3">{children}</div>
    </div>
  );
};

export default Grid;
