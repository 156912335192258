import { EmployeeAuthState } from './types';

/*
 * Selector. The query depends by the state shape
 */
export const getEmployeeId = (state: EmployeeAuthState): number | undefined =>
  state.employeeId;

/*
 * Selector. The query depends by the state shape
 */
export const getEmployeeToken = (
  state: EmployeeAuthState
): string | undefined => state.token;
