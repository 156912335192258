import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from 'components/atoms/Header';
import { enterHome } from 'store/appState';

const TIMEOUT = 15;
const EmployeeSignupSuccess: React.FC = () => {
  const [t] = useTranslation();
  const [timeLeft, setTimeLeft] = useState(TIMEOUT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeLeft) {
      dispatch(enterHome());
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, dispatch]);

  return (
    <div>
      <Header size="lg">{t('info:employeeSignupSuccessHeader')}</Header>
      <p className="text-gray-300 text-base">
        {t('info:employeeSignupSuccessBody', { timeLeft })}
      </p>
    </div>
  );
};

export default EmployeeSignupSuccess;
