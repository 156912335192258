import React, {
  ReactElement,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Base from 'components/templates/Base';
import Card from 'components/atoms/Card';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import Header from 'components/atoms/Header';
import Alert from 'components/atoms/Alert';
import { logInStart, checkLoginStatus } from 'store/auth';
import { RootState } from 'store/configuration';
import LocationState from 'types/Location';

const Login = (): ReactElement => {
  const firstRender = useRef(true);
  const [t] = useTranslation();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { loggedIn, error } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [pass, setPass] = useState<string>('');
  const [user, setUser] = useState<string>('');

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return dispatch(logInStart(user, pass));
  };

  const renderAlert = useCallback(() => {
    if (error) {
      return (
        <Alert
          text={error.message ?? t('error:loginFailed')}
          className="mt-2"
          type="error"
        />
      );
    }
    return null;
  }, [error, t]);

  useEffect(() => {
    if (!loggedIn && firstRender.current) {
      dispatch(checkLoginStatus());
      firstRender.current = false;
    }
    if (loggedIn && !firstRender.current) {
      history.replace(location.state?.from?.pathname || '/');
    }
  });

  return (
    <Base>
      <div className="w-2/5">
        {renderAlert()}
        <Card border="primary" className="p-2 my-2">
          <div className="m-2">
            <div className="m-2 mb-4">
              <Header size="md">{t('info:depLoginHeader')}</Header>
              <p className="text-base text-gray-300">
                {t('info:depLoginBody')}
              </p>
            </div>

            <div className="m-2">
              <form onSubmit={onSubmit}>
                <div className="my-2">
                  <TextInput
                    placeholder={t('common:userId')}
                    type="text"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setUser(event.target.value)
                    }
                  />
                </div>
                <div className="my-2">
                  <TextInput
                    placeholder={t('common:password')}
                    type="password"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setPass(event.target.value)
                    }
                  />
                </div>
                <div className="flex justify-end my-2">
                  <Button text={t('common:logIn')} type="submit" />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </div>
    </Base>
  );
};

export default Login;
