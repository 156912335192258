import {
  AuthStateActionTypes,
  EmployeeAuthState,
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
  EmployeeLoginSuccessAction,
  EmployeeLoginFailedAction,
} from './types';

export const initialState: EmployeeAuthState = {
  loading: false,
  loggedIn: false,
  employeeId: undefined,
  token: undefined,
  error: undefined,
};

const emplouyeeAuthReducer = (
  state = initialState,
  action: AuthStateActionTypes
): EmployeeAuthState => {
  switch (action.type) {
    case CHECK_LOGIN_STATUS:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_PENDING:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_SUCCESS: {
      const fetchAction = action as EmployeeLoginSuccessAction;
      return {
        ...state,
        loading: false,
        loggedIn: true,
        employeeId: fetchAction.payload.employeeId,
        token: fetchAction.payload.token,
        error: undefined,
      };
    }
    case LOGIN_FAILED: {
      const fetchAction = action as EmployeeLoginFailedAction;
      return {
        ...state,
        error: fetchAction.payload,
        loading: false,
      };
    }
    case LOG_OUT:
      return initialState;
  }
  return state;
};

export default emplouyeeAuthReducer;
