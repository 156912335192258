import React, { ReactElement, ReactNode } from 'react';

type BaseProps = {
  children: ReactNode;
};

const Base = ({ children }: BaseProps): ReactElement => (
  <div className="container flex flex-col items-center content-center justify-center h-screen mx-auto p-4">
    {children}
  </div>
);

export default Base;
