import {
  EmployeeAuthError,
  LOG_IN_PENDING,
  LOG_IN_SUCCESS,
  LOGIN_FAILED,
  LOG_OUT,
  CHECK_LOGIN_STATUS,
  EmployeeLoginAction,
  EmployeeLoginSuccessAction,
  EmployeeLoginFailedAction,
  CheckLoginStatusAction,
  LogoutAction,
} from './types';

export const logInStart = (
  employeeId: number,
  pincode: string
): EmployeeLoginAction => ({
  type: LOG_IN_PENDING,
  payload: {
    employeeId,
    pincode,
  },
});

export const logInSuccess = (
  employeeId: number,
  token: string
): EmployeeLoginSuccessAction => ({
  type: LOG_IN_SUCCESS,
  payload: {
    employeeId,
    token,
  },
});

export const logInFailure = (
  message: string,
  statusCode: number
): EmployeeLoginFailedAction => {
  const error: EmployeeAuthError = {
    message,
    statusCode,
  };
  return {
    type: LOGIN_FAILED,
    payload: error,
  };
};

export const logOut = (): LogoutAction => ({
  type: LOG_OUT,
});

export const checkLoginStatus = (): CheckLoginStatusAction => ({
  type: CHECK_LOGIN_STATUS,
});
