import React from 'react';
import { useSelector } from 'react-redux';
import Card from 'components/atoms/Card';
import EmployeeListHeader from 'components/molecules/EmployeeListHeader';
import EmployeeListItem from 'components/molecules/EmployeeListItem';
import Department from 'types/Department';
import Employee from 'types/Employee';
import { isEmpty } from 'lodash';
import { getHomeState } from 'store/appState';
import { RootState } from 'store/configuration';

interface EmployeeListProp {
  department?: Department;
  employees: { entities: Employee[]; result: number[] };
  loading?: boolean;
  selectedEmployee: Employee | null;
  onSelect: (employee: Employee | null) => void;
}

const EmployeeList: React.FC<EmployeeListProp> = ({
  department,
  employees: { entities, result },
  loading,
  selectedEmployee,
  onSelect,
}) => {
  const homeState = useSelector((state: RootState) =>
    getHomeState(state.appState)
  );
  if (!loading && entities && !isEmpty(result)) {
    return (
      <Card border="primary" className="flex flex-col h-full overflow-hidden">
        <div className="border-b-2">
          <EmployeeListHeader
            department={department}
            employeeCount={result?.length ?? 0}
          />
        </div>
        <div className="relative w-full h-full overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full overflow-scroll divide-y-2 divide-gray-200">
            {result?.map((idx) => (
              <EmployeeListItem
                employee={entities[idx]}
                onClick={onSelect}
                active={
                  selectedEmployee ? entities[idx] === selectedEmployee : false
                }
                disabled={
                  homeState === 'answerQuestions' &&
                  (selectedEmployee ? entities[idx] !== selectedEmployee : true)
                }
                key={idx}
              />
            ))}
          </div>
        </div>
      </Card>
    );
  }

  return <div>Loading...</div>;
};

export default EmployeeList;
