import {
  Actions,
  AppStateState,
  HOME,
  EMPLOYEE_LOGIN,
  ANSWER_QUESTIONS,
  FINISH_QUESTIONS,
  NO_QUESTIONS,
  HOME_STATE_ROUTE,
  EMPLOYEE_LOGIN_STATE_ROUTE,
  ANSWER_QUESTIONS_STATE_ROUTE,
  FINISH_QUESTIONS_STATE_ROUTE,
  NO_QUESTIONS_STATE_ROUTE,
  EMPLOYEE_SIGNUP,
  EMPLOYEE_SIGNUP_STATE_ROUTE,
  EMPLOYEE_SIGNUP_SUCCESS,
  EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
} from './types';

import { ensureTransition } from './homeStateTransitions';

export const initialState: AppStateState = {
  homeState: HOME_STATE_ROUTE,
};

const reducer = (state = initialState, action: Actions): AppStateState => {
  switch (action.type) {
    case EMPLOYEE_LOGIN:
      ensureTransition(state.homeState, EMPLOYEE_LOGIN_STATE_ROUTE);

      return {
        ...state,
        homeState: EMPLOYEE_LOGIN_STATE_ROUTE,
      };
    case EMPLOYEE_SIGNUP:
      ensureTransition(state.homeState, EMPLOYEE_SIGNUP_STATE_ROUTE);

      return {
        ...state,
        homeState: EMPLOYEE_SIGNUP_STATE_ROUTE,
      };
    case EMPLOYEE_SIGNUP_SUCCESS:
      ensureTransition(state.homeState, EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE);

      return {
        ...state,
        homeState: EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
      };

    case ANSWER_QUESTIONS:
      ensureTransition(state.homeState, ANSWER_QUESTIONS_STATE_ROUTE);

      return {
        ...state,
        homeState: ANSWER_QUESTIONS_STATE_ROUTE,
      };
    case FINISH_QUESTIONS:
      ensureTransition(state.homeState, FINISH_QUESTIONS_STATE_ROUTE);

      return {
        ...state,
        homeState: FINISH_QUESTIONS_STATE_ROUTE,
      };
    case NO_QUESTIONS:
      ensureTransition(state.homeState, NO_QUESTIONS_STATE_ROUTE);

      return {
        ...state,
        homeState: NO_QUESTIONS_STATE_ROUTE,
      };
    case HOME:
      ensureTransition(state.homeState, HOME_STATE_ROUTE);

      return initialState;
  }

  return state;
};

export default reducer;
