import React, { ReactElement, ReactNode } from 'react';
import { HiOutlineX } from 'react-icons/hi';

type CardProps = {
  border?: 'primary';
  className?: string;
  children: ReactNode;
  onCloseHandler?: () => void;
};

const Card = ({
  border,
  className = '',
  children,
  onCloseHandler,
}: CardProps): ReactElement => {
  return (
    <div
      className={`bg-white ${
        border ? 'border-primary border-l-8' : ''
      } rounded-lg shadow-md ${className}`}
    >
      {onCloseHandler && (
        <div className="w-full flex flex-row items-end justify-end mb-4">
          <div
            className="bg-primary-light bg-opacity-40 rounded"
            onClick={onCloseHandler}
            onKeyPress={onCloseHandler}
            tabIndex={0}
            role="button"
          >
            <HiOutlineX size="25" />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
