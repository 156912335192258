import React from 'react';
import { useSelector } from 'react-redux';
import EmployeeLogin from 'components/organisms/EmployeeLogin';
import QuestionCard from 'components/organisms/QuestionCard';
import NoQuestionsCard from 'components/organisms/NoQuestionsCard';
import { RootState } from 'store/configuration';
import {
  getHomeState,
  HOME_STATE_ROUTE,
  EMPLOYEE_LOGIN_STATE_ROUTE,
  ANSWER_QUESTIONS_STATE_ROUTE,
  FINISH_QUESTIONS_STATE_ROUTE,
  NO_QUESTIONS_STATE_ROUTE,
  EMPLOYEE_SIGNUP_STATE_ROUTE,
  EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
} from 'store/appState';
import FinishedMessage from 'components/molecules/FinishedMessage';
import Employee from 'types/Employee';
import InitialMessage from 'components/molecules/InitialMessage';
import EmployeeSignup from 'components/organisms/EmployeeSignup';
import EmployeeSignupSuccess from 'components/organisms/EmployeeSignupSuccess';

interface HomeStateRouterProps {
  employee: Employee | null;
}
const HomeStateRouter: React.FC<HomeStateRouterProps> = ({ employee }) => {
  const homeState = useSelector((state: RootState) =>
    getHomeState(state.appState)
  );

  if (homeState === EMPLOYEE_SIGNUP_STATE_ROUTE) return <EmployeeSignup />;
  if (homeState === EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE)
    return <EmployeeSignupSuccess />;
  if (!employee) return <InitialMessage />;
  if (homeState === HOME_STATE_ROUTE) return <InitialMessage />;
  if (homeState === EMPLOYEE_LOGIN_STATE_ROUTE)
    return <EmployeeLogin employee={employee} />;
  if (homeState === ANSWER_QUESTIONS_STATE_ROUTE)
    return <QuestionCard employee={employee} />;
  if (homeState === NO_QUESTIONS_STATE_ROUTE)
    return <NoQuestionsCard employee={employee} />;
  if (homeState === FINISH_QUESTIONS_STATE_ROUTE) return <FinishedMessage />;

  return null;
};

export default HomeStateRouter;
