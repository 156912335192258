import React, { ReactElement } from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme, { GlobalStyle } from 'services/theme';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'index.css';
import 'services/i18n';
import { store } from 'store/configuration';
import Router from 'components/templates/Router';
import reportWebVitals from './reportWebVitals';

const App = (): ReactElement => {
  const [t] = useTranslation();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{t('app:name')}</title>
        </Helmet>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </Provider>
  );
};

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;
