import Employee from 'types/Employee';
import {
  FetchAction,
  FetchSuccessAction,
  FetchFailedAction,
  FETCH_EMPLOYEES_FAILED,
  FETCH_EMPLOYEES_PENDING,
  FETCH_EMPLOYEES_SUCCESS,
} from './types';

export const fetchEmployees = (): FetchAction => ({
  type: FETCH_EMPLOYEES_PENDING,
});

export const fetchEmployeesSuccess = (
  entities: Employee[],
  result: number[]
): FetchSuccessAction => ({
  type: FETCH_EMPLOYEES_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const fetchEmployeesFailed = (message: string): FetchFailedAction => ({
  type: FETCH_EMPLOYEES_FAILED,
  payload: {
    message,
  },
});
