import React from 'react';
import Header from 'components/atoms/Header/index';
import Department from 'types/Department';
import DropDown from 'components/atoms/DropDown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LogoutOutline, UserAdd } from 'heroicons-react';
import { logOut } from 'store/auth';
import { enterEmployeeSignup } from 'store/appState';

interface EmployeeListHeaderProp {
  department?: Department;
  employeeCount: number;
}

const EmployeeListHeader: React.FC<EmployeeListHeaderProp> = ({
  department,
  employeeCount,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const menuItems = [
    {
      name: t('common:addEmployee'),
      icon: <UserAdd />,
      onClick: () => dispatch(enterEmployeeSignup()),
    },
    {
      name: t('common:logOut'),
      textColor: 'red',
      icon: <LogoutOutline />,
      onClick: () => dispatch(logOut()),
    },
  ];

  return (
    <div className="flex items-center justify-between h-24 px-6 py-4">
      <div>
        <span className="text-gray-400">
          {department && (
            <Header size="xs">
              Afdeling {department.id} - {department.name}
            </Header>
          )}
        </span>
        <Header size="sm">Medarbejdere ({employeeCount})</Header>
      </div>
      <div className="relative flex float-right">
        <DropDown items={menuItems} />
      </div>
    </div>
  );
};

export default EmployeeListHeader;
