import {
  Actions,
  FetchSuccessAction,
  FetchFailedAction,
  QuestionsState,
  FETCH_QUESTIONS_PENDING,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILED,
} from './types';

export const initialState: QuestionsState = {
  loading: false,
  entities: [],
  result: [],
  error: undefined,
};

const reducer = (state = initialState, action: Actions): QuestionsState => {
  switch (action.type) {
    case FETCH_QUESTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_QUESTIONS_SUCCESS: {
      const fetchAction = action as FetchSuccessAction;
      return {
        ...state,
        loading: false,
        entities: fetchAction.payload.entities,
        result: fetchAction.payload.result,
      };
    }
    case FETCH_QUESTIONS_FAILED: {
      const fetchAction = action as FetchFailedAction;
      return {
        ...state,
        loading: false,
        error: fetchAction.payload,
      };
    }
  }
  return state;
};

export default reducer;
