import Answer from 'types/Answer';
import Question from 'types/Question';
import {
  FETCH_QUESTIONS_PENDING,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILED,
  FinishQuestionActions,
  QUESTIONS_FINISH,
  START_QUESTIONAIRE,
  FetchAction,
  FetchSuccessAction,
  FetchFailedAction,
  StartQuestionaireAction,
} from './types';

export const fetchQuestions = (token: string): FetchAction => ({
  type: FETCH_QUESTIONS_PENDING,
  payload: { token },
});

export const fetchQuestionsSuccess = (
  entities: Question[],
  result: number[]
): FetchSuccessAction => ({
  type: FETCH_QUESTIONS_SUCCESS,
  payload: {
    entities,
    result,
  },
});

export const fetchQuestionsFailed = (message: string): FetchFailedAction => ({
  type: FETCH_QUESTIONS_FAILED,
  payload: {
    message,
  },
});

export const startQuestionaire = (token: string): StartQuestionaireAction => ({
  type: START_QUESTIONAIRE,
  payload: { token },
});

export const finishQuestions = (
  answers: Answer[],
  token?: string
): FinishQuestionActions => ({
  type: QUESTIONS_FINISH,
  payload: { answers, token },
});
