import { NormalizedSchema } from 'normalizr';
import Employee from 'types/Employee';
import ReduxReducer from 'types/ReduxReducer';

const EMPLOYEE_PREFIX = 'EMPLOYEES::';

export interface EmployeesSchemaEntity {
  employees: Employee[];
}

export interface EmployeesState extends ReduxReducer {
  entities: Employee[];
  result: number[];
}

export const FETCH_EMPLOYEES_PENDING = `${EMPLOYEE_PREFIX}Fetching`;
export const FETCH_EMPLOYEES_SUCCESS = `${EMPLOYEE_PREFIX}Fetch succeeded`;
export const FETCH_EMPLOYEES_FAILED = `${EMPLOYEE_PREFIX}Fetch failed`;

export interface FetchAction {
  type: typeof FETCH_EMPLOYEES_PENDING;
}

export interface FetchSuccessAction {
  type: typeof FETCH_EMPLOYEES_SUCCESS;
  payload: {
    entities: Employee[];
    result: number[];
  };
}

export interface FetchFailedAction {
  type: typeof FETCH_EMPLOYEES_FAILED;
  payload: {
    message: string;
  };
}

export type NormalizedEmployees = NormalizedSchema<
  EmployeesSchemaEntity,
  number[]
>;

export type Actions = FetchAction | FetchFailedAction | FetchSuccessAction;
