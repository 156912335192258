import React, { useState, useCallback, useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/atoms/Card';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import Header from 'components/atoms/Header';
import { useTranslation } from 'react-i18next';
import Alert from 'components/atoms/Alert';
import { RootState } from 'store/configuration';
import { isEmpty } from 'lodash';
import { userSignupStart } from 'store/auth';

const EmployeeSignup = (): ReactElement => {
  const dispatch = useDispatch();
  const [finish, setFinish] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [pincode, setPincode] = useState<string>('');
  const [t] = useTranslation();
  const authError = useSelector((state: RootState) => state.auth.error);
  const { error, loading } = useSelector(
    (state: RootState) => state.employeeAuth
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return setFinish(true);
  };

  const renderError = useCallback(() => {
    if (error || authError) {
      return (
        <Alert
          text={
            t(`error:${error?.statusCode ?? authError?.message}`) ??
            t('error:loginFailed')
          }
          className="mt-2"
          type="warning"
        />
      );
    }
    return null;
  }, [error, authError, t]);

  useEffect(() => {
    if (!isEmpty(username) && !isEmpty(pincode) && !isEmpty(name) && finish) {
      dispatch(userSignupStart(username, pincode, name));
      setFinish(false);
    }
  }, [username, pincode, name, setFinish, finish, dispatch]);

  return (
    <>
      <Card className="flex flex-col px-6 py-10">
        <div>
          <Header size="md">{t('info:employeeSignupHeader')} </Header>
          <span className="text-gray-400">
            <Header size="xs">{t('info:employeeSignupBody')}</Header>
          </span>
        </div>
        <div className="w-full mt-4">
          <form onSubmit={onSubmit} autoComplete="off">
            <div className="mt-2">
              <TextInput
                placeholder={t('common:username_email')}
                type="email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUsername(event.target.value)
                }
                required
              />
            </div>
            <div className="mt-2">
              <TextInput
                placeholder={t('common:employee_name')}
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
                required
                autoComplete="off"
              />
            </div>
            <div className="mt-2">
              <TextInput
                placeholder={t('common:pinCode')}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPincode(event.target.value)
                }
                required
                autoComplete="off"
              />
            </div>
            <div className="flex justify-end mt-4">
              <Button
                text={t('common:addEmployee')}
                type="submit"
                loading={loading}
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </Card>
      {renderError()}
    </>
  );
};

export default EmployeeSignup;
