import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/atoms/Header';

const InitialMessage = (): ReactElement => {
  const [t] = useTranslation();
  return (
    <div>
      <Header size="lg">{t('info:initialMessageHeader')}</Header>
      <p className="text-gray-300 text-base">{t('info:initialMessageBody')}</p>
    </div>
  );
};

export default InitialMessage;
