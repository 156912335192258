import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/atoms/Card';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import Header from 'components/atoms/Header';
import Employee from 'types/Employee';
import { useTranslation } from 'react-i18next';
import { logInStart } from 'store/employeeAuth';
import Alert from 'components/atoms/Alert';
import { RootState } from 'store/configuration';

export interface EmployeeLoginProp {
  employee: Employee;
}

const EmployeeLogin: React.FC<EmployeeLoginProp> = ({ employee }) => {
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState<string>('');
  const [t] = useTranslation();
  const { error } = useSelector((state: RootState) => state.employeeAuth);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    return dispatch(logInStart(employee.id, pincode));
  };

  const renderError = useCallback(() => {
    if (error) {
      return (
        <Alert
          text={t(`error:${error.statusCode}`) ?? t('error:loginFailed')}
          className="mt-2"
          type="warning"
        />
      );
    }
    return null;
  }, [error, t]);

  return (
    <>
      <Card className="flex flex-col px-6 py-10">
        <div>
          <Header size="md">
            Medarbejder Login -{' '}
            {employee.name ?? ` ${t('common:employee')} ${employee.id}`}
          </Header>
          <span className="text-gray-400">
            <Header size="xs">{t('info:employeeLoginBody')}</Header>
          </span>
        </div>
        <div className="w-full mt-4">
          <form onSubmit={onSubmit}>
            <div>
              <TextInput
                placeholder={t('common:pinCode')}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPincode(event.target.value)
                }
              />
            </div>
            <div className="flex justify-end mt-4">
              <Button text={t('common:logIn')} type="submit" />
            </div>
          </form>
        </div>
      </Card>
      {renderError()}
    </>
  );
};

export default EmployeeLogin;
