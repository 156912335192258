import React, {
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { DotsVertical } from 'heroicons-react';

export type MenuItem = {
  name: string;
  textColor?: string;
  onClick?: () => void;
  icon?: ReactNode;
};

type Props = {
  items?: MenuItem[];
};

const DropDown = ({ items }: Props): ReactElement => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!open);

  const renderItems = () => {
    if (items?.length) {
      return items.map(({ name, textColor = 'default', icon, onClick }) => (
        <li
          key={name}
          className={`block px-4 py-2 ${
            textColor === 'default'
              ? 'text-gray-900 hover:bg-gray-200 '
              : `text-${textColor}-600 hover:bg-${textColor}-200`
          } no-underline cursor-pointer capitalize hover:no-underline flex flex-row items-center`}
          onClick={onClick}
          onKeyPress={onClick}
          aria-label={name}
          role="menuitem"
        >
          {icon}
          <span className="ml-2">{name}</span>
        </li>
      ));
    }
    return null;
  };

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    },
    [setOpen]
  );

  const clickListener = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open, setOpen]);

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);

  return (
    <div className="flex">
      <button
        className="flex flex-row items-center"
        type="button"
        onClick={toggleDropdown}
        onKeyPress={toggleDropdown}
      >
        <DotsVertical size={25} />
      </button>
      <div
        className={`absolute top-0 right-0 z-30 w-48 mt-12 overflow-auto bg-white rounded shadow-lg ${
          !open ? 'invisible' : ''
        }`}
      >
        <ul className="list-reset">{renderItems()}</ul>
      </div>
    </div>
  );
};

export default DropDown;
