import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/atoms/Header';
import Employee from 'types/Employee';

export interface NoQuestionsCardProps {
  employee: Employee;
}

const NoQuestionsCard: React.FC<NoQuestionsCardProps> = () => {
  const [t] = useTranslation();

  return (
    <div>
      <Header size="lg">{t('info:noQuestionsHeader')}</Header>
      <p className="text-gray-400">{t('info:noQuestionsBody')}</p>
    </div>
  );
};

export default NoQuestionsCard;
