import { put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { Api, URL_CONSTANTS } from 'services/api';
import { FETCH_EMPLOYEES_PENDING, NormalizedEmployees } from './types';
import { fetchEmployeesFailed, fetchEmployeesSuccess } from './actions';
import EmployeeSchema from './schema';

function* employeesFetch() {
  try {
    const res = yield Api.get(URL_CONSTANTS.EMPLOYEES).then(
      (result) => result.data.employees
    );
    const { entities, result }: NormalizedEmployees = yield normalize(res, [
      EmployeeSchema,
    ]);

    yield put(fetchEmployeesSuccess(entities.employees, result));
  } catch (error) {
    yield put(fetchEmployeesFailed(error.message));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onEmployeesFetch() {
  yield takeLatest(FETCH_EMPLOYEES_PENDING, employeesFetch);
}

export default [onEmployeesFetch];
