import { AuthState } from './auth';
import { EmployeeAuthState } from './employeeAuth';
import { EmployeesState } from './employees';
import { QuestionsState } from './questions';
import { AppStateState } from './appState';

/**
 * Action types
 */
export const CLEAR_DATA = 'CLEAR DATA';

export interface ClearDataAction {
  type: typeof CLEAR_DATA;
}

export interface State {
  auth: AuthState;
  employeeAuth: EmployeeAuthState;
  employees: EmployeesState;
  questions: QuestionsState;
  appState: AppStateState;
}
