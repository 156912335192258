import { put, select, takeLatest } from 'redux-saga/effects';
import { Api, URL_CONSTANTS } from 'services/api';
import { enterAnswerQuestions } from 'store/appState';
import { startQuestionaire } from 'store/questions';
import { logInSuccess, logInFailure } from './actions';
import { getEmployeeId, getEmployeeToken } from './selectors';
import {
  LOG_IN_PENDING,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
  EmployeeLoginAction,
} from './types';

const logIn = (employeeId: number, pincode: string) => {
  const locale = 'da';
  return Api.post(`${URL_CONSTANTS.EMPLOYEES}/${employeeId}/authenticate`, {
    password: pincode,
    locale,
  }).then((response) => response.data.token);
};

const invalidateToken = (token: string) => {
  // eslint-disable-next-line no-console
  console.log(token);
};

function* logInWithCredentials(action: EmployeeLoginAction) {
  const {
    payload: { employeeId, pincode },
  } = action;

  try {
    const response = yield logIn(employeeId, pincode);
    yield put(logInSuccess(employeeId, response));
    yield put(startQuestionaire(response));
    yield put(enterAnswerQuestions());
  } catch (error) {
    yield put(logInFailure(error.message, error.response.status));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onLogInStart() {
  yield takeLatest(LOG_IN_PENDING, logInWithCredentials);
}

function* checkAuthenticationStatus() {
  try {
    const employeeId = yield select(getEmployeeId);
    const token = yield select(getEmployeeToken);

    if (employeeId && token) {
      yield put(logInSuccess(employeeId, token));
    }
  } catch (error) {
    yield put(logInFailure(error, error.response.status));

    yield window.location.replace('/login');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onCheckAuthenticationStatusStart() {
  yield takeLatest(CHECK_LOGIN_STATUS, checkAuthenticationStatus);
}

function* logOutHandler() {
  try {
    const token = yield select(getEmployeeToken);
    yield invalidateToken(token);
  } catch (e) {
    throw new Error(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onUserLogOut() {
  yield takeLatest(LOG_OUT, logOutHandler);
}

export default [onLogInStart, onCheckAuthenticationStatusStart, onUserLogOut];
