import React from 'react';

interface HeaderProps {
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const Header: React.FC<HeaderProps> = ({ size, children }) => {
  if (size === 'lg') return <h1 className="text-3xl">{children}</h1>;
  if (size === 'md') return <h2 className="text-2xl">{children}</h2>;
  if (size === 'sm') return <h3 className="text-xl">{children}</h3>;
  if (size === 'xs') return <p className="text-base">{children}</p>;

  return null;
};

export default Header;
