import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Answer from 'types/Answer';
import Header from 'components/atoms/Header';
import Card from 'components/atoms/Card';
import StraightSlider from 'components/atoms/StraightSlider';
import Button from 'components/atoms/Button';
import { fetchQuestions, finishQuestions } from 'store/questions';
import { RootState } from 'store/configuration';
import Employee from 'types/Employee';
import Alert from 'components/atoms/Alert';
import { enterHome } from 'store/appState';

export interface QuestionCardProps {
  employee: Employee;
}

const QuestionCard: React.FC<QuestionCardProps> = () => {
  const firstRender = useRef(true);
  const [t] = useTranslation();
  const [sliderValue, setSliderValue] = useState(0);
  const dispatch = useDispatch();
  const { entities, loading, error, result } = useSelector(
    (state: RootState) => state.questions
  );
  const { token } = useSelector((state: RootState) => state.employeeAuth);
  const [current, setCurrent] = useState<number>(0);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [finished, setFinished] = useState<boolean>(false);
  const [componentError, setComponentError] = useState<boolean>(false);

  const onCloseHandler = () => {
    return dispatch(enterHome());
  };

  const renderError = useCallback(() => {
    if (componentError || error) {
      return (
        <Alert text={t('error:general')} className="mt-2" type="warning" />
      );
    }
    return null;
  }, [componentError, error, t]);

  const onNextClick = useCallback(() => {
    setAnswers([
      ...answers,
      {
        id: entities[result[current]]?.id,
        value: sliderValue,
      },
    ]);

    if (current + 1 < result?.length) {
      setSliderValue(0);
      return setCurrent(current + 1);
    }

    if (!finished) {
      return setFinished(true);
    }
    return setComponentError(true);
  }, [finished, setFinished, answers, entities, sliderValue, result, current]);

  useEffect(() => {
    if (
      firstRender.current &&
      isEmpty(entities) &&
      !loading &&
      !error &&
      token
    ) {
      dispatch(fetchQuestions(token));
      firstRender.current = false;
    }
  }, [entities, loading, error, token, dispatch]);

  useEffect(() => {
    if (finished) dispatch(finishQuestions(answers, token));
  }, [dispatch, token, answers, finished]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!firstRender.current && !entities && isEmpty(result)) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card className="px-6 py-4" onCloseHandler={onCloseHandler}>
        <div className="flex justify-end text-gray-400">
          <Header size="sm">
            {t('common:question')} {current + 1}/{result.length}
          </Header>
        </div>
        <div className="flex my-4 text-black">
          <p className="">{entities[result[current]]?.question}</p>
        </div>
        <StraightSlider onChange={setSliderValue} value={sliderValue} />

        <div className="flex flex-row justify-end">
          <Button
            onClick={onNextClick}
            text={t('common:next')}
            disabled={sliderValue <= 0}
          />
        </div>
      </Card>
      {renderError()}
    </>
  );
};

export default QuestionCard;
