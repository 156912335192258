import React, { ReactElement } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from 'components/views/Login';
import PrivateRoute from 'components/templates/PrivateRoute';
import Home from 'components/views/Home';

const ROUTES = {
  LOGIN: {
    path: '/login',
  },
};

const Router = (): ReactElement => {
  const { LOGIN } = ROUTES;
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <Route path={LOGIN.path} component={Login} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
