import React, { ReactElement, HTMLProps } from 'react';

export interface TextInputProps extends HTMLProps<HTMLInputElement> {
  inputSize?: 'sm' | 'lg';
}

const TextInput = ({ ...rest }: TextInputProps): ReactElement => {
  return (
    <input
      className="w-full h-14 px-4 py-2 text-base placeholder-black bg-gray-100 focus:placeholder-gray-600 rounded-lg focus:ring-primary-light focus:ring-2 focus:outline-none"
      {...rest}
    />
  );
};

export default TextInput;
