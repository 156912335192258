import {
  Actions,
  EmployeesState,
  FetchSuccessAction,
  FETCH_EMPLOYEES_PENDING,
  FETCH_EMPLOYEES_SUCCESS,
} from './types';

export const initialState: EmployeesState = {
  loading: false,
  entities: [],
  result: [],
  error: undefined,
};

const reducer = (state = initialState, action: Actions): EmployeesState => {
  switch (action.type) {
    case FETCH_EMPLOYEES_PENDING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EMPLOYEES_SUCCESS: {
      const fetchAction = action as FetchSuccessAction;
      return {
        ...state,
        loading: false,
        entities: fetchAction.payload.entities,
        result: fetchAction.payload.result,
      };
    }
  }
  return state;
};

export default reducer;
