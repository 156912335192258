import {
  HOME,
  EMPLOYEE_LOGIN,
  EMPLOYEE_SIGNUP,
  ANSWER_QUESTIONS,
  FINISH_QUESTIONS,
  EnterEmployeeLoginAction,
  EnterAnswerQuestionsAction,
  EnterFinishQuestionsAction,
  EnterHomeAction,
  EnterNoQuestionsAction,
  NO_QUESTIONS,
  EnterEmployeeSignupAction,
  EMPLOYEE_SIGNUP_SUCCESS,
  EnterEmployeeSignupSuccessAction,
} from './types';

export const enterEmployeeLogin = (): EnterEmployeeLoginAction => ({
  type: EMPLOYEE_LOGIN,
});

export const enterEmployeeSignup = (): EnterEmployeeSignupAction => ({
  type: EMPLOYEE_SIGNUP,
});

export const enterEmployeeSignupSuccess = (): EnterEmployeeSignupSuccessAction => ({
  type: EMPLOYEE_SIGNUP_SUCCESS,
});

export const enterAnswerQuestions = (): EnterAnswerQuestionsAction => ({
  type: ANSWER_QUESTIONS,
});

export const enterFinishQuestions = (): EnterFinishQuestionsAction => ({
  type: FINISH_QUESTIONS,
});

export const enterNoQuestions = (): EnterNoQuestionsAction => ({
  type: NO_QUESTIONS,
});

export const enterHome = (): EnterHomeAction => ({
  type: HOME,
});
