import Department from 'types/Department';

const AUTH_BASE = 'AUTH::';

export type AuthError = {
  message?: string;
  status?: number;
};
/**
 * State object types
 */
export type AuthState = {
  loading: boolean;
  loggedIn: boolean;
  error?: AuthError;
  department?: Department;
};

/**
 * Action types
 */
export const LOG_IN_PENDING = `${AUTH_BASE}LOG IN PENDING`;
export const LOG_IN_SUCCESS = `${AUTH_BASE}LOG IN SUCCEEDED`;
export const LOGIN_FAILED = `${AUTH_BASE}LOG IN FAILED`;
export const LOG_OUT = `${AUTH_BASE}LOG OUT`;
export const CHECK_LOGIN_STATUS = `${AUTH_BASE}CHECK LOGIN STATUS`;

export const USER_SIGNUP_PENDING = `${AUTH_BASE}Signup pending`;
export const USER_SIGNUP_SUCCESS = `${AUTH_BASE}Signup success`;
export const USER_SIGNUP_FAILED = `${AUTH_BASE}Signup failed`;

export const GET_DEPARTMENT_PENDING = `${AUTH_BASE}Get department`;
export const GET_DEPARTMENT_SUCCESS = `${AUTH_BASE}Get department success`;
export const GET_DEPARTMENT_FAILED = `${AUTH_BASE}Get department failed`;

export interface LoginResponse {
  token: string;
}

export interface LoginAction {
  type: typeof LOG_IN_PENDING;
  payload: {
    username: string | number;
    password: string;
  };
}
export interface LoginSuccessAction {
  type: typeof LOG_IN_SUCCESS;
  payload?: undefined;
}

export interface LoginFailedAction {
  type: typeof LOGIN_FAILED;
  payload?: AuthError;
}

export interface LogoutAction {
  type: typeof LOG_OUT;
  payload?: undefined;
}

export interface UserSignupStart {
  type: typeof USER_SIGNUP_PENDING;
  payload: {
    username: string;
    password: string;
    name: string;
  };
}

export interface UserSignupFailed {
  type: typeof USER_SIGNUP_FAILED;
  payload?: AuthError;
}

export interface UserSignupSuccess {
  type: typeof USER_SIGNUP_SUCCESS;
  payload?: undefined;
}

export interface CheckLoginStatusAction {
  type: typeof CHECK_LOGIN_STATUS;
  payload?: undefined;
}

export interface FetchDepartmentPending {
  type: typeof GET_DEPARTMENT_PENDING;
  payload?: undefined;
}
export interface FetchDepartmentSuccess {
  type: typeof GET_DEPARTMENT_SUCCESS;
  payload?: Department;
}
export interface FetchDepartmentFailed {
  type: typeof GET_DEPARTMENT_FAILED;
  payload?: {
    message: string;
    statusCode: number;
  };
}

export type AuthStateActionTypes =
  | LoginAction
  | LoginSuccessAction
  | LoginFailedAction
  | LogoutAction
  | CheckLoginStatusAction
  | FetchDepartmentPending
  | FetchDepartmentSuccess
  | FetchDepartmentFailed
  | UserSignupFailed
  | UserSignupStart
  | UserSignupSuccess;
