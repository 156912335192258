import { takeLatest, put } from 'redux-saga/effects';
import { Api, URL_CONSTANTS, setHeader, AUTH_HEADER_KEY } from 'services/api';
import clearConsole from 'services/clearConsole';
import { clearData } from 'store/actions';
import { fetchEmployees } from 'store/employees';
import { enterEmployeeSignupSuccess } from 'store/appState';
import { AxiosResponse } from 'axios';
import {
  LOG_IN_PENDING,
  CHECK_LOGIN_STATUS,
  LOG_OUT,
  LoginAction,
  GET_DEPARTMENT_PENDING,
  LoginResponse,
  USER_SIGNUP_PENDING,
  UserSignupStart,
} from './types';
import {
  logInSuccess,
  logInFailure,
  fetchDepartmentSuccess,
  fetchDepartmentFailed,
  userSignupSuccess,
  userSignupFailed,
} from './actions';

const TOKEN_KEY = 'token';
const ID_KEY = 'depId';

const signup = async (
  username: string,
  password: string,
  employeeName: string
) => {
  const locale = 'da';
  const response = await Api.post(`${URL_CONSTANTS.SIGNUP}`, {
    username,
    password,
    employeeName,
    locale,
  });
  return response;
};
const logIn = async (
  departmentId: string | number,
  password: string
): Promise<string | AxiosResponse<string>> => {
  const locale = 'da';
  const response = await Api.post<LoginResponse>(`${URL_CONSTANTS.SESSION}`, {
    departmentId,
    password,
    locale,
  });
  return response.data.token;
};

const invalidateToken = (token: string) => {
  // eslint-disable-next-line no-console
  console.log(token);

  // return Api.delete(`${URL_CONSTANTS.SESSION}?token=${token}`);
};

const checkForToken = () => {
  return window.localStorage.getItem(TOKEN_KEY);
};

const requestDepartment = (id: string) =>
  Api.get(`${URL_CONSTANTS.SESSION}?department_id=${id}&locale=da`).then(
    (res) => res.data.identity
  );

function* logInWithCredentials(action: LoginAction) {
  const {
    payload: { username, password },
  } = action;

  try {
    const response = yield logIn(username, password);
    yield setHeader(AUTH_HEADER_KEY, response);
    yield window.localStorage.setItem(TOKEN_KEY, response);
    yield window.localStorage.setItem(ID_KEY, username.toString());
    yield clearConsole();

    yield put(logInSuccess());
  } catch (error) {
    yield put(logInFailure(error.message));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onLogInStart() {
  yield takeLatest(LOG_IN_PENDING, logInWithCredentials);
}

function* checkAuthenticationStatus() {
  try {
    const sessionID = yield checkForToken();

    if (sessionID) {
      setHeader(AUTH_HEADER_KEY, sessionID);
      yield put(logInSuccess());
    }
  } catch (error) {
    yield put(logInFailure(error));

    yield window.location.replace('/login');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onCheckAuthenticationStatusStart() {
  yield takeLatest(CHECK_LOGIN_STATUS, checkAuthenticationStatus);
}

function* logOutHandler() {
  try {
    const token = yield checkForToken();
    yield invalidateToken(token);
    yield localStorage.removeItem(TOKEN_KEY);
    yield put(clearData());
  } catch (e) {
    throw new Error(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onUserLogOut() {
  yield takeLatest(LOG_OUT, logOutHandler);
}

function* handleFetchDepartment() {
  try {
    const id = yield localStorage.getItem(ID_KEY);
    const identity = yield requestDepartment(id);
    if (identity) {
      yield put(fetchDepartmentSuccess(identity));
    }
  } catch (error) {
    yield put(fetchDepartmentFailed(error.message, error.response.status));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onFetchDepartment() {
  yield takeLatest(GET_DEPARTMENT_PENDING, handleFetchDepartment);
}

function* handleSignup(action: UserSignupStart) {
  try {
    const {
      payload: { username, password, name },
    } = action;
    yield signup(username, password, name);
    yield put(userSignupSuccess());
    yield put(fetchEmployees());
    yield put(enterEmployeeSignupSuccess());
  } catch (e) {
    if (e.isAxiosError) {
      yield put(
        userSignupFailed({ message: e.response.data, status: e.status })
      );
    }
    yield put(userSignupFailed(e));
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* onSignupStart() {
  yield takeLatest(USER_SIGNUP_PENDING, handleSignup);
}

export default [
  onLogInStart,
  onCheckAuthenticationStatusStart,
  onUserLogOut,
  onFetchDepartment,
  onSignupStart,
];
