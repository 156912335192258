import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from 'components/atoms/Header';
import { useTranslation } from 'react-i18next';
import { enterHome } from 'store/appState';

const TIMEOUT = 15;
const FinishedMessage: React.FC = () => {
  const [t] = useTranslation();
  const [timeLeft, setTimeLeft] = useState(TIMEOUT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeLeft) {
      dispatch(enterHome());
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, dispatch]);

  return (
    <div>
      <Header size="lg">{t('info:finishHeader')}</Header>
      <p className="text-base text-gray-400">
        {t('info:finishBody', { timeLeft })}
      </p>
    </div>
  );
};
export default FinishedMessage;
