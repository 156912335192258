import {
  Transition,
  HOME_STATE_ROUTE,
  EMPLOYEE_LOGIN_STATE_ROUTE,
  ANSWER_QUESTIONS_STATE_ROUTE,
  FINISH_QUESTIONS_STATE_ROUTE,
  NO_QUESTIONS_STATE_ROUTE,
  HomeStateState,
  HomeStateTransisionError,
  EMPLOYEE_SIGNUP_STATE_ROUTE,
  EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
} from './types';

const transitions: Transition[] = [
  { from: HOME_STATE_ROUTE, to: EMPLOYEE_LOGIN_STATE_ROUTE },
  { from: HOME_STATE_ROUTE, to: EMPLOYEE_SIGNUP_STATE_ROUTE },
  { from: EMPLOYEE_LOGIN_STATE_ROUTE, to: ANSWER_QUESTIONS_STATE_ROUTE },
  { from: ANSWER_QUESTIONS_STATE_ROUTE, to: FINISH_QUESTIONS_STATE_ROUTE },
  { from: ANSWER_QUESTIONS_STATE_ROUTE, to: NO_QUESTIONS_STATE_ROUTE },
  { from: FINISH_QUESTIONS_STATE_ROUTE, to: HOME_STATE_ROUTE },
  { from: FINISH_QUESTIONS_STATE_ROUTE, to: EMPLOYEE_LOGIN_STATE_ROUTE },
  { from: NO_QUESTIONS_STATE_ROUTE, to: HOME_STATE_ROUTE },
  { from: NO_QUESTIONS_STATE_ROUTE, to: EMPLOYEE_LOGIN_STATE_ROUTE },
  { from: EMPLOYEE_LOGIN_STATE_ROUTE, to: HOME_STATE_ROUTE },
  { from: ANSWER_QUESTIONS_STATE_ROUTE, to: HOME_STATE_ROUTE },
  {
    from: EMPLOYEE_SIGNUP_STATE_ROUTE,
    to: EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
  },
  {
    from: EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
    to: HOME_STATE_ROUTE,
  },
  {
    from: EMPLOYEE_SIGNUP_SUCCESS_STATE_ROUTE,
    to: EMPLOYEE_LOGIN_STATE_ROUTE,
  },
  {
    from: EMPLOYEE_SIGNUP_STATE_ROUTE,
    to: EMPLOYEE_LOGIN_STATE_ROUTE,
  },
  {
    from: EMPLOYEE_LOGIN_STATE_ROUTE,
    to: EMPLOYEE_SIGNUP_STATE_ROUTE,
  },
];

export const getTransitionsForState = (
  fromState: HomeStateState
): Transition[] => {
  return transitions.filter((transition) => transition.from === fromState);
};

export const canTransition = (
  fromState: HomeStateState,
  toState: HomeStateState
): boolean => {
  return getTransitionsForState(fromState).some(
    (transition) => transition.to === toState
  );
};

export const ensureTransition = (
  fromState: HomeStateState,
  toState: HomeStateState
): void => {
  if (fromState === toState) return;
  if (canTransition(fromState, toState)) return;

  throw new HomeStateTransisionError(
    `can't transision from state: ${fromState} to state: ${toState}`
  );
};

export default transitions;
