import React, { ReactElement } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { EmojiHappyOutline, EmojiSadOutline } from 'heroicons-react';

type StraightSliderProps = {
  onChange: (value: number) => void;
  value: number;
};

const StraightSlider = ({
  onChange,
  value,
}: StraightSliderProps): ReactElement => (
  <div className="w-full">
    <div className="flex items-center justify-center text-3xl text-primary">
      {value}
    </div>
    <Slider
      min={0}
      max={10}
      step={1}
      onChange={onChange}
      value={value}
      trackStyle={{ backgroundColor: '#F6A07C', height: 15 }}
      handleStyle={{
        backgroundColor: '#fff',
        height: 25,
        width: 25,
        border: 0,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
      }}
      railStyle={{ backgroundColor: '#9B1359', height: 15 }}
    />
    <div className="flex flex-row items-center justify-between my-4">
      <EmojiSadOutline size={35} color="#9B1359" />
      <EmojiHappyOutline size={35} color="#9B1359" />
    </div>
  </div>
);

export default StraightSlider;
